<template>
  <div class="profile-email profile-field">
    <label :for="generateID('email', email.email_id || index)" class="registration"
      >{{ sharedState.strings.email }} <span v-if="index == 0" class="required" aria-hidden="true">*</span></label
    >
    <input
      :id="generateID('email', email.email_id || index)"
      v-model="email.email_address"
      class="registration input-deletable"
      :disabled="!!sharedState.edit_profile.is_saml_user"
      type="text"
      maxlength="256"
      aria-required="true"
      :aria-describedBy="'form_error_email_address_' + (email.email_id || index)"
      :aria-invalid="!email.email_address"
    />
    <button
      v-if="length > 1"
      :disabled="!!sharedState.edit_profile.is_saml_user"
      class="button button-delete"
      :aria-label="sharedState.strings.delete_email + ' ' + email.email_address"
      @click="handleEmailDelete(index)"
    >
      <span aria-hidden="true">X</span>
    </button>
    <p
      v-show="formError && index === 0 && formError.email_address"
      :id="'form_error_email_address_' + (email.email_id || index)"
      class="error"
      role="alert"
      tabindex="0"
    >
      {{ sharedState.strings.form_error_email_address || 'Please enter a valid email address.' }}
    </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserProfileEmail',
  props: {
    email: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    formError: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['sharedState']),
  },
  mounted: function () {
    this.$set(this.email, 'email_address', this.email.email_address || '')
  },
  methods: {
    generateID: function (name, id) {
      return name + '_' + id
    },
    handleEmailDelete: function (index) {
      delete this.sharedState.edit_profile.email_addresses.splice(index, 1)
      this.$ariaReadText(this.email.email_address + ' ' + this.sharedState.strings.deleted)
    },
  },
}
</script>
