<template>
  <div class="profile-phone profile-field">
    <input
      :id="generateID('phone_number', (phone.phone_id || index))"
      v-model="phone.phone_number"
      class="registration input-deletable"
      type="text"
      maxlength="45"
      :aria-describedby="'phone_type_' + (phone.phone_id || index)"
      :aria-label="sharedState.strings.number"
    />
    <select
      :id="'phone_type_' + (phone.phone_id || index)"
      v-model="phone.phone_type"
      :aria-label="sharedState.strings.phone_type"
      class=""
    >
      <option value="0">{{ sharedState.strings.phone_extra_0 }}</option>
      <option value="1">{{ sharedState.strings.phone_extra_1 }}</option>
      <option value="2">{{ sharedState.strings.phone_extra_2 }}</option>
      <option value="3">{{ sharedState.strings.phone_extra_3 }}</option>
    </select>
    <button
      class="button button-delete"
      :aria-label="
        sharedState.strings.delete_phone + ' ' + phone.phone_number + ' (' + getNumberType(phone.phone_type) + ')'
      "
      @click="handlePhoneDelete(index)"
    >
      <span aria-hidden="true">X</span>
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserProfilePhone',
  props: {
    phone: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['sharedState']),
    numberType: function() {
      return this.sharedState.edit_profile.preferred_language === 'fr'
        ? this.sharedState.strings.numbr + ' ' + this.getNumberType(this.phone.phone_type)
        : this.getNumberType(this.phone.phone_type) + ' ' + this.sharedState.strings.numbr
    },
  },
  mounted: function() {
    this.$set(this.phone, 'phone_number', this.phone.phone_number || '')
  },
  methods: {
    generateID: function(name, id) {
      return name + '_' + id
    },
    getNumberType: function(type) {
      return [
        this.sharedState.strings.phone_extra_0,
        this.sharedState.strings.phone_extra_1,
        this.sharedState.strings.phone_extra_2,
        this.sharedState.strings.phone_extra_3,
      ][type]
    },
    handlePhoneDelete: function(index) {
      delete this.sharedState.edit_profile.phone_numbers.splice(index, 1)
      this.$ariaReadText(this.phone.phone_number + ' ' + this.sharedState.strings.deleted)
      return false
    },
  },
}
</script>
