<template>
  <header :class="['header', { 'active-chat': activechat }]">
    <div class="mainNav background-color color-accent" aria-live="assertive">
      <h1 v-if="title" id="IPRelayHeader" tabindex="-1" class="header-title no-outline">{{ title }}</h1>
      <!-- Ensure empty headers are still shown -->
      <div v-else>&nbsp;</div>
      <a
        :href="sharedState.config && sharedState.config.headerLink ? sharedState.config.headerLink.url : '/'"
        class="brand-logo"
        :aria-label="
          sharedState.config && sharedState.config.headerLink && sharedState.config.headerLink.label
            ? sharedState.config.headerLink.label[sharedState.lang]
            : sharedState.strings.return_to_home
        "
      />
    </div>
  </header>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IprelayHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    activechat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['sharedState']),
  },
  watch: {
    title: {
      handler: function(newValue) {
        const newTitle = this.sharedState.strings.title + (newValue ? ' - ' + newValue : '')
        document.title = newTitle
      },
      immediate: true,
    },
  },
}
</script>
