export const strings = {
  en: {
    title: 'IP Relay',
    welcome: 'Welcome to IP Relay',
    iprelay_title: 'Place IP Relay Call',
    number_to_dial: 'Number to dial',
    block_number: 'Block my number',
    block_name: 'Block my name',
    english: 'English',
    french: 'Français',
    switch_lang: 'Cliquez ici pour accéder au portail en français.',
    switch_lang_code: 'fr',
    access_number: 'Access Number',
    password: 'Password',
    log_in: 'Log In',
    submit: 'Submit',
    loading: 'Loading...',
    forgot_password: 'Forgot your password?',
    reset_password: 'Reset your password',
    invalid_password:
      'Error: The username and password you have entered do not match. Please try again or click on the "Forgot your password?" link to reset your password.',
    invalid_saml_user: 'Error: IP Relay authentication failed. Please contact your service provider for assistance.',
    saml_user_ro: 'SAML User (read only)',
    forced_reset: 'Please reset your password.',
    pw_reset_success: 'Your password has been successfully reset.',
    pw_reset_failure: 'Error: Unable to reset your password at this time. Please try again later.',
    pw_reset_wrong_answer: 'Error: Sorry, your answer is not correct. Please try again.',
    pw_reset_new: 'New Password',
    pw_reset_confirm: 'Confirm New Password',
    pw_reset_match: 'Error: Passwords must match',
    pw_reset_email_sent:
      'Error: A password reset link has been sent to your email. Please follow the instructions in the email.',
    pw_current: 'Current Password',
    pw_reset_incorrect_info: 'Error: Incorrect access number or e-mail address',
    pw_requirements:
      'The password must be minimum 8 characters, containing at least one letter, and at least one number.',
    register: 'Register for a new account',
    register_button_label: 'Register',
    instructions: 'Instructions for Operator',
    call: 'Call',
    emergency: 'Emergency',
    emergency_notice:
      'The emergency button will connect you to the police/RCMP, fire and ambulance services through an IP relay operator. Only use in case of emergency.',
    call_title: 'IP Relay Call with',
    queue_title: 'IP Relay Call - Please Wait',
    queue_wait: 'Please wait while we connect you with an operator.',
    start_call_error: 'Error: Unable to connect your call at this time. Please try again later.',
    name: 'Name',
    number: 'Phone Number',
    operator: 'Operator',
    add: 'Add',
    type_your_message: 'Type your message. Press Enter to send.',
    address_book: 'Address Book',
    filter: 'Filter',
    filtered_by: 'filtered by',
    showing: 'showing',
    messages: 'Messages',
    messages_table_caption: 'Messages list',
    no_messages: 'No messages',
    i_said: 'I said',
    operator_said: 'Operator said',
    delete: 'Delete',
    deleted: 'Deleted',
    delete_all: 'Delete all',
    download_transcript: 'Download Transcript',
    print: 'Print',
    transcript: 'Transcript',
    single_message: 'message',
    plural_message: 'messages',
    undo: 'Undo',
    ab_entry: 'address book entry',
    ab_populated: 'Number to dial changed',
    ab_new_address: 'New address book entry created',
    ab_deleted_address: 'Address book entry deleted',
    ab_delete_confirm: 'Are you sure you want to delete address book entry',
    delete_confirm: 'Are you sure you want to delete',
    hangup_confirm: 'Are you sure you want to end the call?',
    preferences: 'Preferences',
    preferences_saved: 'Preferences saved',
    preview: 'Preview',
    primary_contact: 'Primary Contact',
    user_font_color: 'User Font Color',
    operator_font_color: 'Operator Font Color',
    user_profile_updated: 'User profile updated',
    black_color: 'Black color',
    blue_color: 'Blue color',
    green_color: 'Green color',
    red_color: 'Red color',
    font_size: 'Size',
    operator_font_preview: 'This is what the operator font will look like',
    user_font_preview: 'This is what your font will look like',
    delete_message: 'Delete message',
    delete_address: 'Delete address',
    delete_phone: 'Delete phone number',
    delete_email: 'Delete email address',
    open_message: 'Open message',
    select: 'Select',
    selectable_buttons: 'Selectable buttons',
    pressing_enter_will_delete_entry: 'Delete',
    save: 'Save',
    saved: 'Saved',
    reset: 'Reset',
    cancel: 'Cancel',
    tos: 'Terms of Service',
    tos_link: 'Please review the terms of service located at',
    tos_agree:
      ' By clicking register, I accept the IP Relay terms of service and acknowledge that the IP Relay service is not designed for emergency 911 calls',
    security_question: 'Security Question',
    security_answer: 'Security Answer',
    answer: 'Answer',
    decline: 'Decline',
    incoming_call: 'Incoming call from',
    logout: 'Logout',
    thank_you: 'Thank you',
    active_call: 'Active Call',
    call_transcript: 'Call Transcript',
    registration_thanks: `Thank you for your request for IP Relay service.

IP Relay service is approved based on your account having an active and current Home Phone line.

An email notification will be sent to you once your account has been reviewed. Your confirmation email will contain
your 9 digit IP Relay access number which you will need to access the service.

Please allow 24 - 48 hours for processing.

Thank you.`,
    profile: 'Profile',
    edit_profile: 'Edit Profile',
    prefix: 'Prefix',
    name_prefix: 'Mr.,Mrs.,Miss,Ms.,Dr.',
    preferred_name: 'Preferred Name',
    first_name: 'First Name',
    middle_name: 'Middle Name / Initial',
    last_name: 'Last Name',
    address: 'Address',
    address_type: 'Address Type',
    address_unit: 'Unit',
    address_street_address1: 'Street Address 1',
    address_street_address2: 'Street Address 2',
    address_city: 'City/Town ',
    address_province: 'Province',
    address_postal_code: 'Postal Code',
    address_country: 'Country',
    add_address: 'Add additional address',
    currently_at: 'I am currently at',
    primary_number: 'Primary Number',
    billable_number: 'Billable Phone Number',
    account_number: 'Account Number',
    numbr: 'number',
    phone_extra: 'Alternate Phone Numbers',
    phone_extra_0: 'Alternate',
    phone_extra_1: 'Cell',
    phone_extra_2: 'Business',
    phone_extra_3: 'Emergency',
    phone_type: 'Phone Type',
    add_phone: 'Add additional phone number',
    add_email: 'Add additional email address',
    add_address_book_entry: 'Add Address Book Entry',
    email: 'Email Address',
    preferred_language: 'Preferred Language',
    preferred_co: 'Preferred Carry Over',
    iprelay_to_voice: 'IP Relay to Voice',
    hco: 'HCO',
    vco: 'VCO',
    account_type: 'Account Type',
    residential: 'Residential',
    business: 'Business',
    organization_name: 'Organization Name',
    disconnected: 'disconnected',
    error: 'disconnected',
    hold: 'hold',
    connected: 'connected',
    dialing: 'dialing',
    ringing: 'ringing',
    end_call: 'End Call',
    return_to_call: 'Return to call',
    newer: 'Newer',
    older: 'Older',
    page: 'Page',
    of: 'of',
    initiated: 'Initiated',
    duration: 'Duration',
    brand: 'Brand',
    subcustomer: 'Subcustomer',
    customer: 'Customer',
    user_group: 'User Group',
    error_loading: 'An error occured while loading the page. Please try again later.',
    page_not_found: 'Page not found',
    return_to_home: 'Home',
    show_timestamps: 'Show timestamps',
    show_timestamps_on: 'Timestamps shown',
    show_timestamps_off: 'Timestamps hidden',
    close_conversation: 'Close conversation',
    close_preview: 'Close preview',
    main: 'Main',
    footer: 'Footer',
    address_field_added: 'Address fields added',
    email_field_added: 'Email field added',
    phone_field_added: 'phone filed added',
    form_error: 'Error: Please review the errors below before submitting again',
    form_error_tos_agreed: 'Error: You must agree to the Terms of Service before you can continue',
    form_error_current_password: 'Error: Please enter your current password',
    form_error_new_password: 'Error: New password cannot be blank and must comply with password requirements',
    form_error_verify_password: 'Error: Please verify your password',
    form_error_primary_number: 'Error: Please provide your primary number',
    form_error_first_name: 'Error: Please provide your first name',
    form_error_last_name: 'Error: Please provide your last name',
    form_error_street_address1: 'Error: Please provide your street address',
    form_error_city: 'Error: Please provide your city or town',
    form_error_province: 'Error: Please provide your province',
    form_error_postal_code: 'Error: Please provide your postal code',
    form_error_country: 'Error: Please provide your country',
    form_error_email_address: 'Error: Please enter an email address that is valid and/or has not already been used.',
    form_error_security_question: 'Error: Please provide a security question',
    form_error_security_answer: 'Error: Please provide a security answer',
    form_error_account_number: 'Error: Please provide a valid account number',
    search_by: 'Search By',
    search: 'Search',
    select_status: 'Select Status',
    pending: 'Pending',
    approved: 'Approved',
    denied: 'Denied',
    suspended: 'Suspended',
    terminated: 'Terminated',
    new_user: 'New User',
    export_data_button: 'Export Results',
    iprelay_banner: 'IP Relay Administration',
    role: 'Role',
    status: 'Status',
    administrator: 'Administrator',
    details: 'Details',
    update: 'Update',
    edit: 'Edit',
    new: 'New',
    prev: 'Prev',
    next: 'Next',
    no_results: 'No users match search criteria',
    email_sent: 'Email Sent',
    password_reset_email: 'Send Password Reset Email?',
    no: 'No',
    yes: 'Yes',
    select_brand: 'Select Brand',
    user: 'User',
  },
  fr: {
    title: 'Relais IP',
    welcome: 'Bienvenue au service de relais IP',
    iprelay_title: 'Placer un appel Relais IP',
    number_to_dial: 'Numéro à composer',
    block_number: 'Bloquer mon numéro',
    block_name: 'Bloquer mon nom',
    english: 'English',
    french: 'Français',
    switch_lang: 'For English, click here.',
    switch_lang_code: 'en',
    access_number: "Numéro d'accès",
    password: 'Mot de passe',
    log_in: 'Ouvrir la session',
    submit: 'Soumettre',
    loading: 'Chargement en cours...',
    forgot_password: 'Vous avez oublié votre mot de passe?',
    reset_password: 'Réinitialiser votre mot de passe',
    invalid_password:
      "Erreur: Le numéro d'accès et le mot de passe entrés ne correspondent pas. Veuillez essayer de nouveau ou cliquez le lien «Mot de passe oublié?» afin de réinitialiser votre mot de passe.",
    invalid_saml_user:
      "Erreur: L'authentification par Relais IP a échoué. Veuillez contacter votre fournisseur de services pour obtenir de l'aide.",
    saml_user_ro: 'Utilizateur SAML (lecture seulement)',
    forced_reset: 'Veuillez réinitialiser votre mot de passe.',
    pw_reset_success: 'Votre mot de passe a été réinitialisé avec succès.',
    pw_reset_failure:
      'Erreur: Impossible de réinitialiser votre mot de passe pour le moment. Veuillez réessayer plus tard.',
    pw_reset_wrong_answer: "Erreur: Désolé, votre réponse n'est pas correcte. Veuillez réessayer.",
    pw_reset_new: 'Nouveau mot de passe',
    pw_reset_confirm: 'Confirmez le mot de passe',
    pw_reset_match: 'Mots de passe ne correspondent pas',
    pw_reset_email_sent:
      'Un lien de réinitialisation du mot de passe a été envoyé à votre adresse courriel. Veuillez suivre les instructions dans le message.',
    pw_current: 'Mot de passe actuel',
    pw_reset_incorrect_info: "Erreur: Numéro d'accès ou adresse courriel incorrecte",
    pw_requirements:
      'Votre mot de passe doit contenir au moins 8 caractères, incluant au moins une lettre et un chiffre.',
    register: 'Inscrivez-vous',
    register_button_label: 'Inscrivez-vous',
    instructions: "Instructions pour l'opérateur",
    call: 'Appel',
    emergency: 'Urgence',
    emergency_notice:
      "Le bouton \"Urgence\" vous mettra en contact avec les services d'urgence (police, pompier, ambulance) via la téléphoniste du service de relais IP. Veuillez n'utiliser qu'en cas d'urgence.",
    call_title: 'Appel Relais IP avec',
    queue_title: "Appel Relais IP - S'il vous plaît, attendez",
    queue_wait: 'Veuillez patienter pendant que nous vous connectons avec un opérateur.',
    start_call_error: 'Erreur: Impossible de connecter votre appel en ce moment. Veuillez réessayer plus tard.',
    name: 'Nom',
    number: 'Numéro de téléphone',
    operator: 'Opérateur',
    add: 'Ajouter',
    type_your_message: 'Tapez votre message. Appuyez sur Entrée pour envoyer.',
    address_book: "Carnet d'adresses",
    filter: 'filtre',
    filtered_by: 'filtré par',
    showing: 'montrant',
    messages: 'Messages',
    messages_table_caption: 'Liste de messages',
    no_messages: 'Pas de messages',
    i_said: "J'ai dit",
    operator_said: 'Opérateur a dit',
    delete: 'Supprimer',
    deleted: 'Supprimé',
    delete_all: 'Supprimer tout',
    download_transcript: 'Télécharger la transcription',
    print: 'Imprimer',
    transcript: 'transcription',
    single_message: 'message',
    plural_message: 'messages',
    undo: 'Annuler',
    ab_entry: "Entrée du carnet d'adresses",
    ab_populated: 'Le numéro à composer a changé',
    ab_new_address: "Nouvelle entrée de carnet d'adresses créée",
    ab_deleted_address: "Entrée de carnet d'adresses supprimée",
    ab_delete_confirm: "Êtes-vous sûr de vouloir supprimer une entrée du carnet d'adresses",
    delete_confirm: 'Êtes-vous sûr de vouloir supprimer',
    hangup_confirm: 'Êtes-vous sûr de vouloir mettre fin à lappel?',
    preferences: 'Préférences',
    preferences_saved: 'Préférences enregistrées',
    preview: 'Aperçu',
    primary_contact: 'Contact principal',
    user_font_color: 'Couleur de police utilisateur',
    operator_font_color: 'Couleur de police opérateur',
    user_profile_updated: 'Profil utilisateur mis à jour',
    black_color: 'Couleur noire',
    blue_color: 'Couleur bleue',
    green_color: 'Couleur verte',
    red_color: 'Couleur rouge',
    font_size: 'Taille',
    operator_font_preview: "C'est ce que ressemblera la police de l'opérateur",
    user_font_preview: "C'est à quoi ressemblera votre police",
    delete_message: 'Supprimer le message',
    delete_address: "Supprimer l'adresse",
    delete_phone: 'Supprimer le numéro de téléphone',
    delete_email: "Supprimer l'adresse courriel",
    open_message: 'Ouvrir le message',
    select: 'Sélectionner',
    selectable_buttons: 'Boutons sélectionnables',
    pressing_enter_will_delete_entry: 'Appuyez sur Entrée pour supprimer entrée',
    save: 'Sauvegarder',
    saved: 'Enregistré',
    reset: 'Réinitialiser',
    cancel: 'Annuler',
    tos: 'Modalités de service',
    tos_link: 'Veuillez réviser les conditions de service situées au',
    tos_agree:
      " J'accepte les conditions de service du relais IP et en cliquant sur enregistrer, je reconnais que le service de relais IP ne supporte pas les services d’urgence du 911",
    security_question: 'Question de sécurité',
    security_answer: 'Réponse',
    answer: 'Répondre',
    decline: 'Refuser',
    incoming_call: 'Appel de',
    logout: 'Déconnexion',
    thank_you: 'Merci',
    active_call: 'Appel actif',
    call_transcript: "Transcription d'appel",
    registration_thanks: `Nous vous remercions pour votre commande de service Relais IP.

L’addition de notre service Relais IP a été autorisée sur votre compte étant donné que vous avez notre service
téléphonique résidentiel.

Vous recevrez un courriel lorsque votre compte aura été vérifié. Le courriel de confirmation contiendra votre
numéro d’accès Relais IP de 9 chiffres qui sera nécessaire afin d’accéder au service.

Accordez 24 à 48 heures pour le traitement de la commande.

Nous vous remercions.`,
    profile: 'Profil',
    edit_profile: 'Modifier votre profil',
    prefix: 'Préfixe',
    name_prefix: 'M.,Mme,Mlle,Dr.',
    preferred_name: 'Nom préféré',
    first_name: 'Prénom',
    middle_name: 'Deuxième prénom/initiale',
    last_name: 'Nom de famille',
    address: 'Adresse',
    address_type: "Type d'adresse",
    address_unit: 'App.',
    address_street_address1: 'Adresse postale 1',
    address_street_address2: 'Adresse postale 2',
    address_city: 'Ville ',
    address_province: 'Province',
    address_postal_code: 'Code postal',
    address_country: 'Pays',
    add_address: 'Ajoutez un autre adresse',
    currently_at: 'Je suis actuellement à',
    primary_number: 'Numéro de téléphone principal',
    billable_number: 'Numéro de téléphone facturable',
    account_number: 'Numéro de compte',
    numbr: 'Numéro',
    phone_extra: 'Numéros de téléphone alternatifs',
    phone_extra_0: 'Autre',
    phone_extra_1: 'Cellulaire',
    phone_extra_2: 'Affaires',
    phone_extra_3: 'Urgence',
    phone_type: 'Type de téléphone',
    add_phone: 'Ajouter un autre numéro de téléphone',
    add_email: 'Ajouter une autre adresse courriel',
    add_address_book_entry: "Ajouter une entrée au carnet d'adresses",
    email: 'Adresse courriel',
    preferred_language: 'Langue préférée',
    preferred_co: 'Service sans intervention',
    iprelay_to_voice: 'Relais IP à la voix',
    hco: 'ESI',
    vco: 'PSI',
    account_type: 'Type de compte',
    residential: 'Résidentiel',
    business: 'Affaires',
    organization_name: "Nom de l'organisation",
    disconnected: 'déconnecté',
    error: 'déconnecté',
    hold: 'en attente',
    connected: 'connecté',
    dialing: 'numérotation',
    ringing: 'sonnerie',
    end_call: 'Raccrocher',
    return_to_call: "Retourner à l'appel",
    newer: 'plus récents',
    older: 'plus anciens',
    page: 'Page',
    of: 'de',
    initiated: 'Initié',
    duration: 'Durée',
    brand: 'Marque',
    subcustomer: 'Sous-client',
    customer: 'Client',
    user_group: "Groupe D'utilisateurs",
    error_loading: "Une erreur s'est produite lors du chargement de la page. Veuillez réessayer plus tard.",
    page_not_found: 'Page non trouvée',
    return_to_home: 'Accueil',
    show_timestamps: 'Afficher les horodatages',
    show_timestamps_on: 'Horodatage affiché',
    show_timestamps_off: 'Horodatage caché',
    close_conversation: 'Fermer la conversation',
    close_preview: "Fermer l'aperçu",
    main: 'Principale',
    footer: 'Bas de page',
    address_field_added: "Champs d'adresse ajoutés",
    email_field_added: 'Champ de courriel ajouté',
    phone_field_added: 'Champ de numéro de téléphone ajouté',
    form_error: 'Erreur: veuillez revoir les erreurs ci-dessous avant de soumettre à nouveau',
    form_error_tos_agreed: 'Erreur: vous devez accepter les modalités de service avant de pouvoir continuer',
    form_error_current_password: 'Erreur: veuillez saisir votre mot de passe actuel',
    form_error_new_password:
      'Erreur: le nouveau mot de passe ne peut pas être vide et doit être conforme aux exigences du mot de passe',
    form_error_verify_password: 'Erreur: veuillez vérifier votre mot de passe',
    form_error_primary_number: 'Erreur: veuillez indiquer votre numéro principal',
    form_error_first_name: 'Erreur: veuillez indiquer votre prénom',
    form_error_last_name: 'Erreur: veuillez indiquer votre nom de famille',
    form_error_street_address1: 'Erreur: veuillez indiquer votre adresse municipale',
    form_error_city: 'Erreur: veuillez indiquer votre ville',
    form_error_province: 'Erreur: veuillez indiquer votre province',
    form_error_postal_code: 'Erreur: veuillez indiquer votre code postal',
    form_error_country: 'Erreur: veuillez indiquer votre pays',
    form_error_email_address: 'Erreur: veuillez fournir votre adresse courriel',
    form_error_security_question: 'Erreur: veuillez fournir une question de sécurité',
    form_error_security_answer: 'Erreur: veuillez fournir une réponse de sécurité',
    form_error_account_number: 'Erreur: veuillez fournir votre numéro de compte',
    search_by: 'Recherché par',
    search: 'Chercher',
    select_status: 'Sélectionnez le statut',
    pending: 'En attente',
    approved: 'Approuvé',
    denied: 'Refusé',
    suspended: 'Suspendu',
    terminated: 'Terminé',
    new_user: 'Nouvel utilisateur',
    export_data_button: 'Exporter les résultats',
    iprelay_banner: 'Administration des relais IP',
    role: 'Rôle',
    status: 'Statut',
    administrator: 'Administrateur',
    details: 'Détails',
    update: 'Mise à jour',
    edit: 'Éditer',
    new: 'Nouveau',
    prev: 'Précédent',
    next: 'Prochain',
    no_results: 'Aucun utilisateur ne correspond aux critères de recherche',
    email_sent: 'EEmail envoyé',
    password_reset_email: 'Envoyer un e-mail de réinitialisation du mot de passe?',
    no: 'Non',
    yes: 'Oui',
    select_brand: 'Sélectionnez la marque',
    user: 'Utilisateur',
  },
}
