<template>
  <div class="profile-address">
    <div v-if="sharedState.edit_profile.user_id" class="profile-address_type profile-field">
      <label :for="generateID('address_type', (address.address_id || index))" class="registration">{{
        sharedState.strings.address_type
      }}</label>
      <input
        :id="generateID('address_type', (address.address_id || index))"
        v-model="address.address_type"
        class="registration input-deletable"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        type="text"
        maxlength="45"
        @change="handleAddressTypeChange"
      />
      <button
        v-if="length > 1"
        class="button button-delete"
        :aria-label="sharedState.strings.delete_address + ' ' + address.address_type"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        @click="handleAddressDelete(index)"
      >
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div class="profile-unit profile-field">
      <label :for="generateID('unit', (address.address_id || index))" class="registration">{{
        sharedState.strings.address_unit
      }}</label>
      <input
        :id="generateID('unit', (address.address_id || index))"
        v-model="address.unit"
        class="registration"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        type="text"
        maxlength="45"
      />
    </div>
    <div class="profile-streetAddress1 profile-field">
      <label :for="generateID('street_address1', (address.address_id || index))" class="registration"
        >{{ sharedState.strings.address_street_address1 }}<span class="required" aria-hidden="true">*</span></label
      >
      <input
        :id="generateID('street_address1', (address.address_id || index))"
        v-model="address.street_address1"
        class="registration"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        type="text"
        maxlength="256"
        aria-required="true"
        :aria-describedBy="'form_error_street_address1_' + (address.address_id || index)"
        :aria-invalid="!address.street_address1"
      />
      <p
        v-show="formError && index === 0 && formError.street_address1"
        :id="'form_error_street_address1_' + (address.address_id || index)"
        class="error"
        role="alert"
        tabindex="0"
      >
        {{ sharedState.strings.form_error_street_address1 }}
      </p>
    </div>
    <div class="profile-streetAddress2 profile-field">
      <label :for="generateID('street_address2', (address.address_id || index))" class="registration">{{
        sharedState.strings.address_street_address2
      }}</label>
      <input
        :id="generateID('street_address2', (address.address_id || index))"
        v-model="address.street_address2"
        class="registration"
        type="text"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        maxlength="256"
      />
    </div>
    <div class="profile-city profile-field">
      <label :for="generateID('city', (address.address_id || index))" class="registration"
        >{{ sharedState.strings.address_city }}<span class="required" aria-hidden="true">*</span></label
      >
      <input
        :id="generateID('city', (address.address_id || index))"
        v-model="address.city"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        class="registration"
        type="text"
        maxlength="128"
        aria-required="true"
        :aria-describedBy="'form_error_city_' + (address.address_id || index)"
        :aria-invalid="!address.city"
      />
      <p v-show="formError && index === 0 && formError.city" :id="'form_error_city_' + (address.address_id || index)" class="error" role="alert" tabindex="0">
        {{ sharedState.strings.form_error_city }}
      </p>
    </div>
    <div class="profile_province profile-field">
      <label :for="generateID('province', (address.address_id || index))" class="registration"
        >{{ sharedState.strings.address_province }} <span class="required" aria-hidden="true">*</span></label
      >
      <select
        :id="generateID('province', (address.address_id || index))"
        v-model="address.province"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        class="registration"
        aria-required="true"
        :aria-describedBy="'form_error_province_' + (address.address_id || index)"
        :aria-invalid="!address.province"
      >
        <option value="AB">AB</option>
        <option value="BC">BC</option>
        <option value="MB">MB</option>
        <option value="NB">NB</option>
        <option value="NF">NF</option>
        <option value="NL">NL</option>
        <option value="NS">NS</option>
        <option value="NT">NT</option>
        <option value="NU">NU</option>
        <option value="ON">ON</option>
        <option value="PE">PE</option>
        <option value="QC">QC</option>
        <option value="SK">SK</option>
        <option value="YT">YT</option>
      </select>
      <p
        v-show="formError && index === 0 && formError.province"
        :id="'form_error_province_' + (address.address_id || index)"
        class="error"
        role="alert"
        tabindex="0"
      >
        {{ sharedState.strings.form_error_province }}
      </p>
    </div>
    <div class="profile-postalCode profile-field">
      <label :for="generateID('postalCode', (address.address_id || index))" class="registration"
        >{{ sharedState.strings.address_postal_code }} <span class="required" aria-hidden="true">*</span></label
      >
      <input
        :id="generateID('postalCode', (address.address_id || index))"
        v-model="address.postal_code"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        class="registration"
        type="text"
        maxlength="7"
        aria-required="true"
        :aria-describedBy="'form_error_postal_code_' + (address.address_id || index)"
        :aria-invalid="!address.postal_code"
      />
      <p
        v-show="formError && index === 0 && formError.postal_code"
        :id="'form_error_postal_code_' + (address.address_id || index)"
        class="error"
        role="alert"
        tabindex="0"
      >
        {{ sharedState.strings.form_error_postal_code }}
      </p>
    </div>
    <div class="profile-country profile-field">
      <label :for="generateID('country', (address.address_id || index))" class="registration"
        >{{ sharedState.strings.address_country }} <span class="required" aria-hidden="true">*</span></label
      >
      <select
        :id="generateID('country', (address.address_id || index))"
        v-model="address.country"
        class="registration"
        :disabled="!!sharedState.edit_profile.is_saml_user"
        aria-required="true"
        :aria-describedBy="'form_error_country_' + (address.address_id || index)"
        :aria-invalid="!address.country"
      >
        <option value="Canada">Canada</option>
      </select>
      <p
        v-show="formError && index === 0 && formError.country"
        :id="'form_error_country_' + (address.address_id || index)"
        class="error"
        role="alert"
        tabindex="0"
      >
        {{ sharedState.strings.form_error_country }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserProfileAddress',
  props: {
    address: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    formError: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['sharedState']),
  },
  mounted: function() {
    this.$set(this.address, 'street_address1', this.address.street_address1 || '')
    this.$set(this.address, 'city', this.address.city || '')
    this.$set(this.address, 'province', this.address.province || '')
    this.$set(this.address, 'postal_code', this.address.postal_code || '')
    this.$set(this.address, 'country', this.address.country || 'Canada')
  },
  methods: {
    generateID: function(name, id) {
      return name + '_' + id
    },
    handleAddressDelete: function(index) {
      var deleted = this.sharedState.edit_profile.addresses.splice(index, 1)
      if (deleted[0].address_id && deleted[0].address_type == this.sharedState.edit_profile.preferred_address) {
        this.sharedState.edit_profile.preferred_address = this.sharedState.edit_profile.addresses[0].address_type
      }
      this.$ariaReadText(
        this.address.address_type + ' ' + this.sharedState.strings.address + '' + this.sharedState.strings.deleted
      )
    },
    handleAddressTypeChange: function(event) {
      //Vuejs workaround, force updating list of addresses
      if (event.target.oldValue && event.target.oldValue == this.sharedState.edit_profile.preferred_address) {
        this.sharedState.edit_profile.preferred_address = event.target.value
      }
      event.target.oldValue = event.target.value
    },
  },
}
</script>
