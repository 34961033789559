import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		sharedState: {
			users: [],
		},
	},
	mutations: {
		setSharedState(state, payload) {
			state.sharedState = payload.sharedState
		},
	},
	actions: {},
	getters: {
		sharedState: (state) => state.sharedState,
	},
})
