<template>
  <footer :class="{ 'active-chat': activechat }">
    <nav
      id="footer_links"
      class="footer_links no-print background-color color-accent"
      :aria-label="sharedState.strings.footer"
    >
      <template
        v-if="
          sharedState.config &&
            sharedState.config.footerLinks &&
            sharedState.config.footerLinks.list &&
            sharedState.config.footerLinks.list[sharedState.lang]
        "
      >
        <ul class="footer-links__list">
          <li v-for="link in sharedState.config.footerLinks.list[sharedState.lang]" :key="link.label">
            <a :href="link.href" target="_blank" rel="noopener" class="footer_link color-accent"> {{ link.label }} </a>
          </li>
        </ul>
      </template>
    </nav>
  </footer>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IprelayFooter',
  props: {
    activechat: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['sharedState']),
  },
}
</script>
<style scoped>
.footer_link {
  font-weight: bold;
  text-decoration: none;
  margin-right: 1rem;
}
.footer-links__list {
  margin: 1rem 0;
  display: inline-flex;
  list-style: none;
  flex-wrap: wrap;
}
</style>
