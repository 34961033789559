<template>
  <div id="user-profile" class="user-profile">
    <div v-if="profile.user_id">
      <!-- Span needed for screen reader to read editProfileHeading -->
      <span class="speechHidden">&nbsp;</span>
      <h2 id="editProfileHeading" tabindex="-1" class="profile-title no-outline">
        {{ sharedState.strings.edit_profile }}
      </h2>
    </div>
    <div v-else>
      <h2 class="profile-title register-title">
        {{ sharedState.strings.register_header || sharedState.strings.register }}
      </h2>
      <div v-if="sharedState.strings.register_subheader" v-html="sharedState.strings.register_subheader" />
    </div>

    <div aria-live="assertive">
      <p v-show="form_error" ref="form_error" v-focusoninsert class="error" role="alert" tabindex="0">
        {{ sharedState.strings.form_error }}
      </p>
    </div>
    <div class="profile-form">
      <div
        v-if="!profile.user_id && admin && sharedState.subCustomerList && sharedState.config.role === 2"
        class="profile-subcustomer profile-field"
      >
        <label for="subcustomer-input" class="registration"
          >{{ sharedState.strings.subcustomer || 'Subcustomer'
          }}<span class="required" aria-hidden="true">*</span></label
        >
        <select
          id="subcustomer-input"
          v-model="profile.subcustomer"
          :disabled="!!profile.is_saml_user"
          class="registration"
          aria-required="true"
        >
          <option v-for="subcustomer in sharedState.subCustomerList" :key="subcustomer.rid" :value="subcustomer.rid">
            {{ subcustomer.name }}
          </option>
        </select>
      </div>
      <div v-if="admin" class="profile-brand profile-field">
        <label for="brand-input" class="registration">{{ sharedState.strings.user_group || 'User Group' }}</label>
        <select id="brand-input" v-model="profile.user_group" class="registration">
          <option v-for="group in filteredUserGroups" :key="group.rid" :value="group.rid">
            {{ group.group_name }}
          </option>
        </select>
      </div>
      <div v-if="profile.access_number" class="profile-access_number profile-field">
        <label for="access_number" class="registration">{{ sharedState.strings.access_number }}</label>
        <input
          id="access_number"
          v-model="profile.access_number"
          :disabled="!!profile.is_saml_user"
          name="access_number"
          class="registration"
          readonly="readonly"
          type="text"
          maxlength="24"
        />
      </div>
      <div class="profile_salutation profile-field">
        <label for="salutation" class="registration">{{ sharedState.strings.prefix }}</label>
        <select
          id="salutation"
          v-model="profile.salutation"
          :disabled="!!profile.is_saml_user"
          name="salutation"
          class="registration"
        >
          <option v-for="prefix in prefixList" :key="prefix" :value="prefix">{{ prefix }}</option>
        </select>
      </div>
      <div class="profile-preferredName profile-field">
        <label for="preferredName" class="registration">{{ sharedState.strings.preferred_name }}</label>
        <input
          id="preferredName"
          v-model="profile.preferred_name"
          :disabled="!!profile.is_saml_user"
          name="preferredName"
          class="registration"
          type="text"
          maxlength="45"
        />
      </div>
      <div class="profile-firstName profile-field">
        <label for="firstName" class="registration"
          >{{ sharedState.strings.first_name }} <span class="required" aria-hidden="true">*</span></label
        >
        <input
          id="firstName"
          v-model="profile.first_name"
          :disabled="!!profile.is_saml_user"
          name="firstName"
          class="registration"
          type="text"
          maxlength="45"
          aria-required="true"
          aria-describedBy="form_error_first_name"
          :aria-invalid="!profile.first_name"
        />
        <p
          v-show="form_error && form_error.first_name"
          id="form_error_first_name"
          class="error"
          role="alert"
          tabindex="0"
        >
          {{ sharedState.strings.form_error_first_name }}
        </p>
      </div>
      <div class="profile-middleName profile-field">
        <label for="middleName" class="registration">{{ sharedState.strings.middle_name }} </label>
        <input
          id="middleName"
          v-model="profile.middle_name"
          :disabled="!!profile.is_saml_user"
          name="middleName"
          class="registration"
          type="text"
          maxlength="45"
        />
      </div>
      <div class="profile-lastName profile-field">
        <label for="lastName" class="registration"
          >{{ sharedState.strings.last_name }} <span class="required" aria-hidden="true">*</span></label
        >
        <input
          id="lastName"
          v-model="profile.last_name"
          :disabled="!!profile.is_saml_user"
          name="lastName"
          class="registration"
          type="text"
          maxlength="45"
          aria-required="true"
          aria-describedBy="form_error_last_name"
          :aria-invalid="!profile.last_name"
        />
        <p
          v-show="form_error && form_error.last_name"
          id="form_error_last_name"
          class="error"
          role="alert"
          tabindex="0"
        >
          {{ sharedState.strings.form_error_last_name }}
        </p>
      </div>

      <div class="profile-address-block">
        <UserProfileAddress
          v-for="(address, index) in profile.addresses"
          :key="address.address_id"
          :address="address"
          :index="index"
          :length="profile.addresses.length"
          :form-error="form_error"
        />
        <button
          v-if="profile.user_id"
          :disabled="!!profile.is_saml_user"
          class="registration profile_add_button color-primary"
          @click="handleAddAddress"
        >
          {{ sharedState.strings.add_address }}
        </button>
      </div>
      <div v-if="profile.user_id" class="profile-field">
        <label for="preferredAddress" class="registration">{{ sharedState.strings.currently_at }}</label>
        <select
          id="preferredAddress"
          v-model="profile.preferred_address"
          name="preferredAddress"
          :disabled="!!profile.is_saml_user"
          class="registration"
        >
          <option v-for="address in profile.addresses" :key="address.address_id" :value="address.address_type">
            {{ address.address_type }}
          </option>
        </select>
      </div>
      <div class="profile-number-block self_clear">
        <div class="primary_number profile-field">
          <label for="primary_number" class="registration"
            >{{ sharedState.strings.primary_number }}
            <span v-if="!profile.user_id || admin" class="required" aria-hidden="true">*</span></label
          >
          <input
            id="primary_number"
            v-model="profile.primary_number"
            :readonly="(!admin && profile.user_id) || !!profile.is_saml_user"
            name="primary_number"
            class="registration"
            type="text"
            maxlength="45"
            aria-required="true"
            :aria-describedby="
              form_error && form_error.primary_number ? 'form_error_primary_number' : 'primary_number_descr'
            "
            :aria-invalid="!profile.primary_number"
          />
          <div id="primary_number_descr" class="registration-extra">[{{ sharedState.strings.billable_number }}]</div>
          <p
            v-show="form_error && form_error.primary_number"
            id="form_error_primary_number"
            class="error"
            role="alert"
            tabindex="0"
          >
            {{ sharedState.strings.form_error_primary_number }}
          </p>
        </div>
        <div v-if="sharedState.config" class="account_number profile-field">
          <label for="account_number" class="registration"
            >{{ sharedState.strings.account_number || 'Account Number' }}
            <span v-if="!profile.user_id || admin" class="required" aria-hidden="true">*</span></label
          >
          <input
            id="account_number"
            v-model="profile.account_number"
            :readonly="(!admin && profile.user_id) || !!profile.is_saml_user"
            name="account_number"
            class="registration"
            type="text"
            maxlength="64"
            aria-required="true"
            aria-describedBy="form_error_account_number"
            :aria-invalid="!profile.account_number"
            @focus="editingAccountNumber = true"
            @blur="editingAccountNumber = false"
          />
          <p
            v-show="profile.account_number && !editingAccountNumber && !accountNumberValidated"
            id="form_error_account_number"
            class="error"
            role="alert"
            tabindex="0"
          >
            {{ sharedState.strings.form_error_account_number }}
          </p>
          <p
            v-show="form_error && form_error.account_number_used"
            id="form_error_account_number2"
            class="error"
            role="alert"
            tabindex="0"
          >
            {{
              sharedState.strings.form_error_account_number_used || 'Error: Please provide a different account number'
            }}
          </p>
        </div>
        <fieldset
          v-if="profile.user_id && profile.phone_numbers && profile.phone_numbers.length"
          class="profile-phone_extra profile-field"
        >
          <legend class="registration">{{ sharedState.strings.phone_extra }}</legend>
          <UserProfilePhone
            v-for="(phone, index) in profile.phone_numbers"
            :key="phone.phone_id"
            :phone="phone"
            :index="index"
            :length="profile.phone_numbers.length"
          />
        </fieldset>
        <div v-if="profile.user_id" class="add-phone-button-div">
          <button class="profile_add_button color-primary" :disabled="!!profile.is_saml_user" @click="handleAddPhone">
            {{ sharedState.strings.add_phone }}
          </button>
        </div>
      </div>
      <div class="profile-email-block">
        <UserProfileEmail
          v-for="(email, index) in profile.email_addresses"
          :key="email.email_id"
          :email="email"
          :index="index"
          :length="profile.email_addresses.length"
          :form-error="form_error"
        />
        <button
          v-if="profile.user_id"
          class="registration profile_add_button color-primary"
          :disabled="!!profile.is_saml_user"
          @click="handleAddEmail"
        >
          {{ sharedState.strings.add_email }}
        </button>
      </div>
      <fieldset class="profile-preferred_language profile-field">
        <legend class="registration">
          {{ sharedState.strings.preferred_language }}
        </legend>
        <div class="preferred_language-inputs">
          <label for="preferred_language_en" class="preferred_language-label" lang="en"
            ><input
              id="preferred_language_en"
              v-model="profile.preferred_language"
              name="preferred_language"
              :disabled="!!profile.is_saml_user"
              value="en"
              type="radio"
              lang="en"
            />
            {{ sharedState.strings.english }}</label
          >
          <label for="preferred_language_fr" class="preferred_language-label" lang="fr"
            ><input
              id="preferred_language_fr"
              v-model="profile.preferred_language"
              name="preferred_language"
              :disabled="!!profile.is_saml_user"
              value="fr"
              type="radio"
              lang="fr"
            />
            {{ sharedState.strings.french }}</label
          >
        </div>
      </fieldset>
      <fieldset class="profile-preferred_co profile-field">
        <legend class="registration">{{ sharedState.strings.preferred_co }}</legend>
        <div class="preferred_co-inputs">
          <label for="preferred_co_iprelay_to_voice" class="preferred_co-label">
            <input
              id="preferred_co_iprelay_to_voice"
              v-model="profile.preferred_co"
              name="preferred_co"
              :disabled="!!profile.is_saml_user"
              value=""
              type="radio"
            />
            {{ sharedState.strings.iprelay_to_voice }}</label
          >
          <label for="preferred_co_hco" class="preferred_co-label">
            <input
              id="preferred_co_hco"
              v-model="profile.preferred_co"
              name="preferred_co"
              :disabled="!!profile.is_saml_user"
              value="hco"
              type="radio"
            />
            {{ sharedState.strings.hco }}
          </label>
          <label for="preferred_co_vco" class="preferred_co-label">
            <input
              id="preferred_co_vco"
              v-model="profile.preferred_co"
              name="preferred_co"
              :disabled="!!profile.is_saml_user"
              value="vco"
              type="radio"
            />
            {{ sharedState.strings.vco }}
          </label>
        </div>
      </fieldset>
      <fieldset class="profile-accountType profile-field">
        <legend class="registration">{{ sharedState.strings.account_type }}</legend>
        <label for="accountType_business" class="account_type__label">
          <input
            id="accountType_business"
            v-model="profile.account_type"
            name="account_type"
            :disabled="!!profile.is_saml_user"
            value="0"
            type="radio"
          />
          {{ sharedState.strings.business }}
        </label>
        <label for="accountType_residential" class="account_type__label">
          <input
            id="accountType_residential"
            v-model="profile.account_type"
            name="account_type"
            :disabled="!!profile.is_saml_user"
            value="1"
            type="radio"
          />
          {{ sharedState.strings.residential }}
        </label>
      </fieldset>

      <div v-show="profile.account_type == 0" class="profile-organization profile-field">
        <label for="organizationName" class="registration">{{ sharedState.strings.organization_name }}</label>
        <input
          id="organizationName"
          v-model="profile.organization_name"
          :disabled="!!profile.is_saml_user"
          name="organizationName"
          class="registration"
          type="text"
          maxlength="128"
        />
      </div>
      <div v-if="!admin" class="profile-new_password profile-field">
        <div id="password_requirements" class="bold">{{ sharedState.strings.pw_requirements }}</div>
        <label for="new_password" class="registration"
          >{{ sharedState.strings.pw_reset_new }}
          <span v-if="!profile.user_id" class="required" aria-hidden="true">*</span></label
        >
        <input
          id="new_password"
          v-model="new_password"
          :disabled="!!profile.is_saml_user"
          name="new_password"
          class="registration"
          type="password"
          maxlength="256"
          aria-required="true"
          :aria-describedBy="
            form_error && form_error.new_password ? 'form_error_new_password' : 'password_requirements'
          "
          :aria-invalid="!new_password && !profile.user_id && !admin"
        />
        <p
          v-show="form_error && form_error.new_password"
          id="form_error_new_password"
          class="error"
          role="alert"
          tabindex="0"
        >
          {{ sharedState.strings.form_error_new_password }}
        </p>
      </div>
      <div v-if="new_password || (!profile.user_id && !admin)" class="profile-verify_password profile-field">
        <label :class="[{ error: new_password !== verify_password }, 'registration']" for="verify_password"
          >{{ sharedState.strings.pw_reset_confirm }} <span class="required" aria-hidden="true">*</span></label
        >
        <input
          id="verify_password"
          v-model="verify_password"
          name="verify_password"
          class="registration"
          type="password"
          maxlength="256"
          aria-required="true"
          :aria-describedBy="form_error && form_error.verify_password ? 'form_error_verify_password' : 'pw_reset_match'"
          :aria-invalid="new_password !== verify_password"
        />
        <p
          v-show="form_error && form_error.verify_password"
          id="form_error_verify_password"
          class="error"
          role="alert"
          tabindex="0"
        >
          {{ sharedState.strings.form_error_verify_password }}
        </p>
        <div id="pw_reset_match" class="error" aria-live="polite">
          <span v-if="new_password !== verify_password">{{ sharedState.strings.pw_reset_match }}</span>
        </div>
      </div>
      <div v-if="new_password && profile.user_id && !admin" class="profile-current_password profile-field">
        <label for="current_password" class="registration"
          >{{ sharedState.strings.pw_current }} <span class="required" aria-hidden="true">*</span></label
        >
        <input
          id="current_password"
          v-model="current_password"
          :disabled="!!profile.is_saml_user"
          name="current_password"
          class="registration"
          type="password"
          maxlength="256"
          aria-required="true"
          aria-describedBy="form_error_current_password"
          :aria-invalid="!current_password"
        />
        <p
          v-show="form_error && form_error.current_password"
          id="form_error_current_password"
          class="error"
          role="alert"
          tabindex="0"
        >
          {{ sharedState.strings.form_error_current_password }}
        </p>
      </div>
      <div class="profile-securityQuestion profile-field">
        <label for="securityQuestion" class="registration"
          >{{ sharedState.strings.security_question }} <span class="required" aria-hidden="true">*</span></label
        >
        <input
          id="securityQuestion"
          v-model="profile.security_question"
          :disabled="!!profile.is_saml_user"
          name="securityQuestion"
          class="registration"
          type="text"
          maxlength="256"
          aria-required="true"
          aria-describedBy="form_error_security_question"
          :aria-invalid="!profile.security_question"
        />
        <p
          v-show="form_error && form_error.security_question"
          id="form_error_security_question"
          class="error"
          role="alert"
          tabindex="0"
        >
          {{ sharedState.strings.form_error_security_question }}
        </p>
      </div>
      <div class="profile-securityAnswer profile-field">
        <label for="securityAnswer" class="registration"
          >{{ sharedState.strings.security_answer }} <span class="required" aria-hidden="true">*</span></label
        >
        <input
          id="securityAnswer"
          v-model="profile.security_answer"
          :disabled="!!profile.is_saml_user"
          name="securityAnswer"
          class="registration"
          type="text"
          maxlength="128"
          aria-required="true"
          aria-describedBy="form_error_security_answer"
          :aria-invalid="!profile.security_answer"
        />
        <p
          v-show="form_error && form_error.security_answer"
          id="form_error_security_answer"
          class="error"
          role="alert"
          tabindex="0"
        >
          {{ sharedState.strings.form_error_security_answer }}
        </p>
      </div>
      <div v-if="!profile.user_id" class="profile-tos">
        <strong>{{ sharedState.strings.tos }}</strong>
        <div
          v-if="sharedState.config && sharedState.config['html_' + sharedState.lang + '_tos']"
          class="tos__embedded"
          tabindex="0"
          v-html="sharedState.config['html_' + sharedState.lang + '_tos']"
        ></div>
        <p>
          {{ sharedState.strings.tos_link }}
          <a v-if="sharedState.strings.tos_url" :href="sharedState.strings.tos_url" target="_blank" rel="noopener">{{
            sharedState.strings.tos_url
          }}</a>
        </p>
        <input id="tos_checkbox" v-model="tos_agreed" type="checkbox" aria-describedBy="form_error_tos_agreed" />
        <label for="tos_checkbox" class="inline">{{ sharedState.strings.tos_agree }} </label>
        <p
          v-show="form_error && form_error.tos_agreed"
          id="form_error_tos_agreed"
          class="error"
          role="alert"
          tabindex="0"
        >
          {{ sharedState.strings.form_error_tos_agreed }}
        </p>
      </div>
      <button
        v-if="profile.user_id"
        id="updateButton"
        :aria-disabled="!canUpdate || !!profile.is_saml_user"
        :class="['button', 'color-primary', { 'button--disabled': !canUpdate }]"
        @click="handleUpdateButton"
      >
        {{ sharedState.strings.save }}
      </button>
      <button
        v-else
        id="registerButton"
        :aria-disabled="!canRegister"
        :class="['button', 'color-primary', { 'button--disabled': !canRegister }]"
        @click="handleRegisterButton"
      >
        {{ sharedState.strings.register_button_label }}
      </button>
      <button
        v-if="profile.user_id"
        id="cancelButton"
        class="button color-primary button-margin"
        @click="handleCancelButton"
      >
        {{ sharedState.strings.cancel }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import common from '../lib/common'
import UserProfileAddress from './UserProfileAddress'
import UserProfileEmail from './UserProfileEmail'
import UserProfilePhone from './UserProfilePhone'
import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'

export default {
  name: 'UserProfile',
  components: { UserProfileAddress, UserProfileEmail, UserProfilePhone },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    selectedSubcustomerID: {
      type: Number,
      required: false,
      default: 0,
    },
    userGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => {
    return {
      tos_agreed: false,
      new_password: '',
      verify_password: '',
      current_password: '',
      profile: {},
      form_error: null,
      editingAccountNumber: false,
      editingEmailAddress: false,
    }
  },
  computed: {
    ...mapGetters(['sharedState']),

    canRegister: function () {
      return (
        this.sharedState.config &&
        this.tos_agreed &&
        (this.admin ||
          (this.new_password !== '' && this.new_password === this.verify_password && this.passwordMeetsRequirements)) &&
        (this.sharedState.config.defaultAccountNumberOverride || this.accountNumberValidated) &&
        this.profile.primary_number &&
        this.profile.first_name &&
        this.profile.last_name &&
        this.profile.addresses[0] &&
        this.profile.addresses[0].street_address1 &&
        this.profile.addresses[0].city &&
        this.profile.addresses[0].province &&
        this.profile.addresses[0].postal_code &&
        this.profile.addresses[0].country &&
        this.profile.email_addresses[0] &&
        this.validateEmail(this.profile.email_addresses[0].email_address) &&
        this.profile.security_question &&
        this.profile.security_answer
      )
    },
    canUpdate: function () {
      return (
        (this.new_password === '' ||
          (this.new_password === this.verify_password && this.current_password && this.passwordMeetsRequirements)) &&
        this.profile.primary_number &&
        this.profile.first_name &&
        this.profile.last_name &&
        this.profile.addresses[0] &&
        this.profile.addresses[0].street_address1 &&
        this.profile.addresses[0].city &&
        this.profile.addresses[0].province &&
        this.profile.addresses[0].postal_code &&
        this.profile.addresses[0].country &&
        this.profile.email_addresses[0] &&
        this.profile.email_addresses[0].email_address &&
        this.profile.account_type &&
        this.profile.security_question &&
        this.profile.security_answer
      )
    },
    passwordMeetsRequirements: function () {
      return this.new_password.length > 7 && this.new_password.match(/[A-Za-z]/) && this.new_password.match(/[0-9]/)
    },
    accountNumberValidated: function () {
      return this.profile.account_number && /^[A-Z0-9._+-]{8,24}$/i.test(this.profile.account_number.trim())
        ? true
        : false
    },
    brandlist: function () {
      return this.sharedState.brandlist
        ? this.sharedState.brandlist.filter(
            (brand) => this.sharedState.config.role !== 2 || brand.subcustomer_id === this.profile.subcustomer
          )
        : []
    },
    prefixList: function () {
      return this.sharedState.strings.name_prefix && this.sharedState.strings.name_prefix.split(',')
    },
    filteredUserGroups: function () {
      return this.userGroups.filter((ug) => ug.subcustomer_id === this.profile.subcustomer)
    },
  },
  mounted: function () {
    this.profile = Object.assign(
      {
        addresses: [{ address_type: '' }],
        phone_numbers: [],
        email_addresses: [{}],
        preferred_language: '',
        preferred_co: '',
        preferred_address: '',
        account_type: '1',
        primary_number: '',
        account_number: '',
        salutation: '',
        first_name: '',
        last_name: '',
        security_question: '',
        security_answer: '',
      },
      cloneDeep(this.value),
      this.sharedState.edit_profile
    )
    this.$set(this.profile, 'preferred_language', this.profile.preferred_language === 'fr' ? 'fr' : 'en')
    this.sharedState.edit_profile = this.profile
    if (this.sharedState.eventHub) {
      this.sharedState.eventHub.$emit('scrollToBottom')
    }
  },
  methods: {
    validateEmail: function (value) {
      return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim()) ? true : false
    },
    handleAddAddress: function () {
      this.profile.addresses.push({ address_type: '' })
      this.$ariaReadText(this.sharedState.strings.address_field_added)
    },
    handleAddPhone: function () {
      this.profile.phone_numbers.push({
        phone_type: '0',
      })
      this.$ariaReadText(this.sharedState.strings.phone_field_added)
    },
    handleAddEmail: function () {
      this.profile.email_addresses.push({})
      this.$ariaReadText(this.sharedState.strings.email_field_added)
    },
    handleUpdateButton: function () {
      if (!this.canUpdate) {
        this.form_error = {
          current_password: this.new_password !== '' && !this.current_password,
          new_password: !(this.new_password === '' || (this.current_password && this.passwordMeetsRequirements)),
          verify_password: this.new_password && this.new_password !== this.verify_password,
          primary_number: !this.profile.primary_number,
          first_name: !this.profile.first_name,
          last_name: !this.profile.last_name,
          street_address1: !(this.profile.addresses[0] && this.profile.addresses[0].street_address1),
          city: !(this.profile.addresses[0] && this.profile.addresses[0].city),
          province: !(this.profile.addresses[0] && this.profile.addresses[0].province),
          postal_code: !(this.profile.addresses[0] && this.profile.addresses[0].postal_code),
          country: !(this.profile.addresses[0] && this.profile.addresses[0].country),
          email_address: !(this.profile.email_addresses[0] && this.profile.email_addresses[0].email_address),
          account_type: !this.profile.account_type,
          security_question: !this.profile.security_question,
          security_answer: !this.profile.security_answer,
        }
        if (this.$refs.form_error) {
          this.$refs.form_error.focus()
        }
        return
      }
      this.form_error = null
      var variables = {}
      variables.profile = {}
      variables.profile.user_id = this.profile.user_id
      variables.profile.user_group = this.profile.user_group
      variables.profile.salutation = this.profile.salutation
      variables.profile.preferred_name = this.profile.preferred_name
      variables.profile.first_name = this.profile.first_name
      variables.profile.middle_name = this.profile.middle_name
      variables.profile.last_name = this.profile.last_name
      variables.profile.preferred_language = this.profile.preferred_language
      variables.profile.preferred_co = this.profile.preferred_co
      variables.profile.preferred_address = this.profile.preferred_address
      variables.profile.primary_number = this.profile.primary_number
      variables.profile.account_number = this.profile.account_number
      variables.profile.account_type = this.profile.account_type
      variables.profile.organization_name = this.profile.organization_name
      variables.profile.security_question = this.profile.security_question
      variables.profile.security_answer = this.profile.security_answer
      variables.profile.hearing_level = this.profile.hearing_level
      variables.profile.addresses = this.profile.addresses
      variables.profile.phone_numbers = this.profile.phone_numbers
      variables.profile.email_addresses = this.profile.email_addresses
      variables.profile.new_password = this.new_password
      variables.profile.current_password = this.current_password

      this.$ga.event('Profile', 'Update', this.admin ? 'By Admin' : 'By User')
      common.postAjax(
        '/graphql',
        {
          query:
            'mutation Profile($profile: InputProfile!) { updateProfile(profile: $profile) { user_id first_name last_name user_group }}',
          variables: JSON.stringify(variables),
        },
        () => {
          if (this.admin) {
            this.$emit('performReSearch')
          }
          if (this.$router) {
            this.$router.push('/')
          }
          this.sharedState.showProfile = false
          this.$emit('input', this.profile)
          this.sharedState.edit_profile = {}
        },
        [{ header: 'Accept', value: 'application/json' }]
      )
      this.$ariaReadText(this.sharedState.strings.user_profile_updated)
    },
    handleRegisterButton: function () {
      if (!this.canRegister) {
        this.form_error = {
          tos_agreed: !this.tos_agreed,
          new_password: !(this.admin || (this.new_password !== '' && this.passwordMeetsRequirements)),
          verify_password: this.new_password && this.new_password !== this.verify_password,
          account_number: !(this.sharedState.config.defaultAccountNumberOverride && this.profile.account_number),
          primary_number: !this.profile.primary_number,
          first_name: !this.profile.first_name,
          last_name: !this.profile.last_name,
          street_address1: !(this.profile.addresses[0] && this.profile.addresses[0].street_address1),
          city: !(this.profile.addresses[0] && this.profile.addresses[0].city),
          province: !(this.profile.addresses[0] && this.profile.addresses[0].province),
          postal_code: !(this.profile.addresses[0] && this.profile.addresses[0].postal_code),
          country: !(this.profile.addresses[0] && this.profile.addresses[0].country),
          email_address: !(
            this.profile.email_addresses[0] && this.validateEmail(this.profile.email_addresses[0].email_address)
          ),
          security_question: !this.profile.security_question,
          security_answer: !this.profile.security_answer,
          subcustomer: !this.profile.subcustomer && this.sharedState.config.role === 2,
        }
        if (this.$refs.form_error) {
          this.$refs.form_error.focus()
        }
        return
      }
      this.form_error = null
      let post_data = {}
      if (this.sharedState.config.role === 2) {
        if (
          this.profile.brand &&
          !this.sharedState.brandlist.find(
            (brand) => brand.subcustomer_id === this.profile.subcustomer && brand.rid === this.profile.brand
          )
        ) {
          this.profile.brand = 0
        }
      }
      post_data = { ...this.profile }
      post_data.new_password = this.new_password
      post_data.addresses[0].address_type = this.profile.account_type ? 'Home' : 'Work'
      post_data.account_type = post_data.account_type || 1
      post_data.account_number = this.sharedState.config.defaultAccountNumberOverride || post_data.account_number
      post_data.email = this.profile.email_addresses[0].email_address
      post_data.account_number = this.profile.account_number
      this.$ga.event('Profile', 'Register', this.admin ? 'By Admin' : 'By User')

      axios
        .post('/register', post_data)
        .then((ret) => {
          this.sharedState.showProfile = false
          this.$set(this.sharedState, 'registeredUserInfo', ret.data)
        })
        .catch((err) => {
          let message = err.response.data.message
          if (message === 'email_invalid') {
            this.form_error ? (this.form_error.email_address = true) : (this.form_error = { email_address: true })
          } else if (message === 'account_invalid') {
            this.form_error
              ? (this.form_error.account_number_used = true)
              : (this.form_error = { account_number_used: true })
          }
        })
    },
    handleCancelButton: function () {
      this.sharedState.showProfile = false
      if (!this.admin && this.$router) {
        this.$router.push('/')
      }
      this.sharedState.edit_profile = {}
    },
  },
}
</script>
<style scoped>
.tos__embedded {
  width: 100%;
  height: 10em;
  border: solid 1px black;
  overflow-y: scroll;
}
.preferred_co-label,
.account_type__label {
  margin-right: 0.8rem;
}
</style>
