/* eslint no-console: 0 */
var dateFormat = require('dateformat')

/*  global ActiveXObject */
function postAjax(url, data, success, headers, options) {
  if (!options) {
    options = {}
  }
  var params =
    typeof data == 'string'
      ? data
      : Object.keys(data)
          .map(function(k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
          })
          .join('&')

  var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP')

  if (options.method === 'GET') {
    url = `${url}?${params}`
  }

  xhr.open(options.method || 'POST', url)
  xhr.onreadystatechange = function() {
    if (xhr.readyState > 3 && xhr.status == 200) {
      success(xhr.responseText)
    }
  }
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
  for (var i = 0; i < headers.length; i++) {
    xhr.setRequestHeader(headers[i].header, headers[i].value)
  }
  xhr.send(params)
  return xhr
}

function setLanguage(state, newLang) {
  if (newLang != 'en' && newLang != 'fr') {
    const windowString = window.location.toString().toLowerCase()
    if (windowString.indexOf('_enregistrement') > 0) {
      newLang = 'fr'
    } else if (windowString.indexOf('_registration') > 0) {
      newLang = 'en'
    } else if (localStorage['lang']) {
      newLang = localStorage['lang'] == 'fr' ? 'fr' : 'en'
    } else {
      if (windowString.indexOf('relaisip') > 0) {
        newLang = 'fr'
      } else {
        newLang = 'en'
      }
    }
  }
  state.strings = state.defaultStrings[newLang]
  state.lang = newLang
  localStorage['lang'] = newLang
  document.documentElement.setAttribute('lang', newLang)
  return state
}

function padTime(i) {
  if (i < 10) {
    i = '0' + i
  } // add zero in front of numbers < 10
  return i
}

function secondsToHMS(seconds) {
  let s = Math.round(seconds % 60)
  seconds = Math.floor(seconds / 60)
  let m = Math.round(seconds % 60)
  seconds = Math.floor(seconds / 60)
  let h = Math.round(seconds % 60)
  let ph = padTime(h)
  let pm = padTime(m)
  let ps = padTime(s)
  return ph + ':' + pm + ':' + ps
}

const parseResponse = res => {
  let parsedResponse

  try {
    parsedResponse = JSON.parse(res)
  } catch (e) {
    return
  }

  return parsedResponse
}

function loadConfig(url) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(parseResponse(xhr.responseText))
      } else {
        reject()
      }
    }
    xhr.send()
  })
}

const customSheet = function() {
  var style = document.createElement('style')
  // WebKit hack :(
  style.appendChild(document.createTextNode(''))
  document.head.appendChild(style)
  return style.sheet
}

const setUpAPM = function(apm) {
  const levels = {
    0: 'CONSOLE',
    1: 'ALERT',
    2: 'CRITICAL',
    3: 'ERROR',
    4: 'WARNING',
    5: 'NOTICE',
    6: 'INFO',
    7: 'DEBUG',
  }
  apm.addLabels({ source: window.location.pathname })
  return function(logLevel, errorMessage) {
    if (typeof errorMessage === 'object') {
      errorMessage = JSON.stringify(errorMessage)
    }
    const prefix = dateFormat(new Date().getTime(), 'yy/mm/dd HH:MM:ss:l')
    arguments[0] = prefix + ' ' + levels[logLevel] + ': '
    if (logLevel > 4) {
      console.log.apply(null, arguments)
    } else {
      console.error.apply(null, arguments)
    }
    apm.captureError(errorMessage)
  }
}

const triggerFileDownload = function(filename, url, options = {}) {
  return new Promise(resolve => {
    const { newWindow, type = 'data:application/octet-stream' } = options

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(new Blob([url], { type }))
      return resolve()
    }

    const anchor = document.createElement('a')

    anchor.setAttribute('href', `${type},${url}`)
    anchor.setAttribute('download', filename)
    anchor.style.display = 'none'
    anchor.textContent = filename

    if (newWindow) {
      anchor.setAttribute('target', '_blank')
    }

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    resolve()
  })
}
export default {
  postAjax,
  setLanguage,
  padTime,
  secondsToHMS,
  loadConfig,
  customSheet,
  setUpAPM,
  triggerFileDownload,
}
